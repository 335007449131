body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
  width: 100%;
  font-size: 14px;
}

/* Larger screens can use larger font sizes */
@media screen and (min-height: 900px) {
  html, body {
    font-size: 18px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  min-height: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media print {
  #root {
    height: auto;
    display: block;
  }

  @page {
    margin-bottom: 0;
  }
}